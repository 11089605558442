body {
  overflow-x: hidden;
}
#header {
  position: relative;
  background: #fff;
  z-index: 99999;
  font-size: 14px;
  line-height: 1.2;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31), 0 1px 1px 0 rgba(9, 30, 66, 0.25);
  color: #000;
  background: #fff;
  font: 16px/1.2 "SFProText", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
}

#header .container {
  max-width: 1600px;
  padding: 3px 16px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-wrap: wrap;
	flex-wrap: wrap; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#header.nav-area-active .header-tools {
  display: none;
}

#header.tools-active .navigation {
  display: none;
}

.logo-holder {
  width: 132px;
  position: absolute;
  top: 5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.logo-holder .logo {
  margin: 0 auto 6px;
  /* width: 32px; */
}

.logo-holder .date {
  font-size: 11px;
  line-height: 1.2;
  color: #6b778c;
  text-align: center;
}

.right-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-wrap: wrap;
	flex-wrap: wrap; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  /* width: 50%; */
  padding: 16px 0 16px 66px;
}

.header-tools {
  padding: 8px 16px;
}

.header-tools > li {
  border-bottom: 1px solid #dfe1e5;
}

.header-tools .tool-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1.1;
  font-size: 14px;
  color: #5e6c84;
  position: relative;
  padding: 14px 10px 14px 8px;
  position: relative;
}
.text {
  text-transform: capitalize;
}
.header-tools .tool-link:before {
  content: "\f054";
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 1;
  font-size: 12px;
  color: #99aac4;
  font-family: "fontawesome";
}

.header-tools .tool-link:hover {
  color: #37598c;
}

.header-tools .tool-link .ico-holder {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-tools .tool-link .icon-more {
  font-size: 3px;
}

.header-tools .tool-link .icon-report {
  font-size: 15px;
}

.header-tools .tool-link .icon-settings {
  font-size: 16px;
}

.header-tools .tool-link .icon-question-circle {
  font-size: 16px;
}

.header-tools .tool-link .text {
  color: #5f7aa3;
}

.popup-holder.active-drop .popup {
  left: 0;
  z-index: 30;
}

.popup-holder .popup {
  padding: 20px 16px;
  width: 100%;
  min-height: calc(100vh - 58px);
  position: absolute;
  top: 0;
  background: #fff;
  left: -9999px;
}

.popup-holder .popup-header {
  text-align: center;
  position: relative;
  padding: 10px 30px;
}

.popup-holder .popup-header .title {
  font-size: 16px;
  line-height: 1.25;
  color: #172b4d;
}

.popup-holder .popup-header .back {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  font-size: 12px;
  color: #42526e;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.popup-holder .popup-header .back:hover {
  color: #37598c;
}

.popup-holder .list {
  padding: 7px 0;
  position: relative;
}

.popup-holder .list + .list:before {
  content: "";
  position: absolute;
  top: 0;
  left: -999px;
  right: -999px;
  height: 1px;
  background: #dfe1e5;
}

.popup-holder .list > li {
  border-bottom: 1px solid #dfe1e5;
}

.popup-holder .list > li a {
  padding: 16px 10px 16px 8px;
  display: block;
}

.list {
  padding: 0 16px;
}

.list > li {
  border-bottom: 2px solid #dfe1e5;
}

.list > li:last-child {
  border-bottom: none;
}

.list > li a {
  display: block;
  padding: 16px 0;
  color: #5f7aa3;
}

.list > li a:hover {
  color: #00000080;
  text-decoration-line: none;
}

.user-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-wrap: wrap;
	flex-wrap: wrap; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 48px;
}

.user-circle {
  width: 24px;
  height: 24px;
  margin: 0 2px 0 0;
}

.user-circle a {
  display: block;
  width: 24px;
  height: 24px;
  background: #00000080;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 50%;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-circle a:hover {
  background: #294267;
}

.header-open-close {
  height: 24px;
  width: 18px;
  position: relative;
}

.header-open-close.active .opener {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.header-open-close .opener {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-wrap: wrap;
	flex-wrap: wrap; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  /* -ms-flex-pack: center;
	justify-content: center; */
  width: 100%;
  height: 100%;
  line-height: 1;
  font-size: 5px;
  color: #8993a4;
}

.header-open-close .slide {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  width: 234px;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
    0 4px 8px -2px rgba(9, 30, 66, 0.25);
  border-radius: 3px;
  text-align: left;
  z-index: 10;
  margin: 11px -8px 0 0;
}

.nav-opener-holder {
  width: 50%;
  padding: 0 66px 0 0;
}

.nav-active {
  /* overflow: hidden; */
}

.nav-opener {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
}

.nav-opener:hover:before,
.nav-opener:hover:after,
.nav-opener:hover span {
  /* background: #42526e; */
}

.nav-opener:before,
.nav-opener:after {
  /* content: ""; */
}

.nav-opener:before,
.nav-opener:after,
.nav-opener span {
  /* height: 2px;
	position: absolute;
	top: 50%;
	left: 15%;
	right: 15%;
	margin: -1px 0 0;
	background: #b3bac5;
	border-radius: 2px; */
}

.nav-active .nav-opener:before,
.nav-active .nav-opener:after,
.nav-active .nav-opener span {
  /* background: #fff; */
}

.nav-opener:before {
  top: 30%;
}
.nav-opener {
  color: #b3bac5;
}
.nav-opener :hover {
  color: black;
}
.nav-active .nav-opener:before {
  top: 50%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-opener:after {
  top: 70%;
}

.nav-active .nav-opener:after {
  top: 50%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav-active .nav-opener span {
  /* opacity: 0; */
}

.nav-drop {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  height: calc(100vh - 58px);
  margin: 2px 0 0;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
}

.nav-active .nav-drop {
  opacity: 1;
  visibility: visible;
}

.navigation {
  padding: 14px 16px 8px;
  border-bottom: 3px solid #dfe1e5;
}

.navigation > li {
  border-top: 1px solid #dfe1e5;
}

.navigation > li:first-child {
  border-top: none;
}

.navigation > li.active > a {
  color: #37598c;
}

.navigation > li > a {
  color: #53677f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 10px 16px 8px;
}

.navigation > li > a:hover {
  color: #00000080;
}
.navigation > li > button:hover {
  color: #00000080;
}

.navigation > li > a .ico-holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: -2px 16px -2px 0;
}

.navigation > li > a .ico-holder .icon {
  line-height: 1;
  font-size: 15px;
}

.navigation > li > a .ico-holder .icon.icon-bullet-list {
  font-size: 8px;
}

.navigation > li > a .ico-holder .icon.icon-Path {
  font-size: 14px;
}

.drop-holder .drop-opener {
  position: relative;
}

.drop-holder .drop-opener:before {
  /* content: "\e90c"; */
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 1;
  font-size: 10px;
  color: #99aac4;
  font-family: "icomoon";
}

.drop-holder .dropdown {
  padding: 20px 16px;
  position: absolute;
  width: 100%;
  left: -9999px;
  top: 0;
  min-height: calc(100vh - 58px);
}

.drop-holder .dropdown .drop-header {
  text-align: center;
  position: relative;
  padding: 10px 30px;
}

.drop-holder .dropdown .drop-header .title {
  font-size: 16px;
  line-height: 1.25;
  color: #172b4d;
}

.drop-holder .dropdown .drop-header .back {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  font-size: 12px;
  color: #42526e;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.drop-holder .dropdown .drop-header .back:hover {
  color: #37598c;
}

.drop-holder.active-drop .dropdown {
  left: 0;
  background: #fff;
  z-index: 35;
}

.drop-list {
  padding: 7px 0;
  position: relative;
}

.drop-list + .drop-list:before {
  content: "";
  position: absolute;
  top: 0;
  left: -999px;
  right: -999px;
  height: 1px;
  background: #dfe1e5;
}

.drop-list > li {
  border-bottom: 1px solid #dfe1e5;
}

.drop-list > li a {
  color: #5f7aa3;
  padding: 16px 10px 16px 8px;
  display: block;
}

.drop-list > li a:hover {
  color: #37598c;
}

#wrapper {
  overflow: unset;
  position: relative;
  width: 100%;
}

.resize-active * {
  -webkit-transition: none !important;
  transition: none !important;
}

.sf-pro-text {
  font-family: "SFProText", "Arial", "Helvetica Neue", "Helvetica", sans-serif !important;
}

.sf-pro-display {
  font-family: "SFProDisplay", "Arial", "Helvetica Neue", "Helvetica",
    sans-serif !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

@media (min-width: 1023px) {
  #header.nav-area-active .header-tools {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .header-tools {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* -ms-flex-wrap: wrap;
		flex-wrap: wrap; */
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 12px 0 0;
    width: calc(100% - 48px);
  }

  .header-tools > li {
    margin: 0 0 0 12px;
    border-bottom: none;
    position: relative;
  }

  .header-tools .tool-link {
    -webkit-box-pack: center;
    /* -ms-flex-pack: center;
		justify-content: center; */
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 12px;
    line-height: 1;
    color: #b3bac5;
  }

  .header-tools .tool-link:before {
    display: none;
  }

  .header-tools .tool-link:hover {
    color: #5f7aa3;
  }

  .header-tools .tool-link:hover .text {
    opacity: 1;
    visibility: visible;
  }

  .header-tools .tool-link .ico-holder {
    width: auto;
    height: auto;
    margin: 0;
    display: inline-block;
    vertical-align: top;
  }

  .header-tools .tool-link .icon-more {
    font-size: 4px;
  }

  .header-tools .tool-link .icon-report {
    font-size: 18px;
  }

  .header-tools .tool-link .icon-settings {
    font-size: 20px;
  }

  .header-tools .tool-link .icon-question-circle {
    font-size: 20px;
  }

  .header-tools .tool-link .text {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 12px;
    line-height: 1.2;
    font-weight: 500;
    border-radius: 3px;
    color: #fff;
    background: #172b4d;
    display: inline-block;
    vertical-align: top;
    padding: 5px 8px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
  }

  .popup-holder {
    height: 24px;
    width: 24px;
  }

  .popup-holder.popup-active .popup-opener {
    color: #5f7aa3;
  }

  .popup-holder.popup-active .popup-opener .text {
    opacity: 0;
    visibility: hidden;
  }

  .popup-holder.active-drop .popup {
    left: auto;
  }

  .popup-holder .popup {
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    width: 240px;
    box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
      0 4px 8px -2px rgba(9, 30, 66, 0.25);
    border-radius: 3px;
    text-align: left;
    z-index: 10;
    margin: 17px 0 0 0;
    padding: 0;
    min-height: 0;
  }

  .popup-holder .popup-header {
    display: none !important;
  }

  .popup-holder .list {
    padding: 5px 0;
  }

  .popup-holder .list + .list {
    border-top: 1px solid #dfe1e5;
  }

  .popup-holder .list + .list:before {
    display: none;
  }

  .popup-holder .list > li {
    border-bottom: none;
  }

  .popup-holder .list > li a {
    padding: 5px 16px;
  }

  .user-circle {
    margin: 0;
  }

  .header-open-close {
    width: 24px;
  }

  .header-open-close .slide {
    margin: 17px 5px 0 0;
  }

  .nav-drop .header-tools {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  #header {
    font-size: 12px;
  }

  #header.tools-active .navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .logo-holder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding: 0 0 8px;
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .logo-holder .logo {
    margin: 0;
  }

  .logo-holder .date {
    width: calc(100% - 32px);
    padding: 0 0 4px 4px;
    text-align: left;
  }

  .right-block {
    /* width: 170px; */
    padding: 0;
  }

  .header-tools {
    padding: 0 5px 0 0;
  }

  .header-tools > li {
    margin: 0 0 0 5px;
  }

  .header-tools .tool-link .text {
    margin: 8px 0 0;
  }

  .popup-holder .popup {
    margin: 15px 0 0 0;
  }

  .user-circle {
    margin: 0 3px 0 0;
  }

  .header-open-close {
    width: 18px;
  }

  .header-open-close .slide {
    margin: 15px 5px 0 0;
  }

  .nav-opener-holder {
    display: none;
  }

  .nav-drop {
    width: calc(100% - 302px);
    position: static;
    background: none;
    height: auto;
    margin: 0;
    overflow: visible;
    opacity: 1;
    visibility: visible;
  }

  .navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    border-bottom: none;
  }

  .navigation > li {
    margin: 0 8px;
    padding: 18px 0;
    border-top: none;
    position: relative;
  }

  .navigation > li.active:before {
    opacity: 1;
    visibility: visible;
  }

  .navigation > li:before {
    content: "\f054";
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #37598c;
    opacity: 0;
    visibility: hidden;
    font-family: "fontawesome";
  }

  .navigation > li > a {
    padding: 0;
  }

  .navigation > li > a .ico-holder {
    width: 22px;
    height: 22px;
    margin: -4px 4px -4px 0;
  }

  .navigation > li > a .ico-holder .icon {
    font-size: 16px;
  }

  .navigation > li > a .ico-holder .icon.icon-bullet-list {
    font-size: 8px;
  }

  .navigation > li > a .text.view-mobile {
    display: none;
  }

  .drop-holder.active .drop-opener:before {
    -webkit-transform: translateY(-50%) rotate(-180deg);
    -ms-transform: translateY(-50%) rotate(-180deg);
    transform: translateY(-50%) rotate(-180deg);
  }

  .drop-holder .drop-opener {
    /* padding: 0 15px 0 0 !important; */
  }

  .drop-holder .drop-opener:before {
    /* content: "\e90a"; */
    font-size: 5px;
  }

  .drop-holder .dropdown {
    background: #fff;
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 210px;
    box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31),
      0 4px 8px -2px rgba(9, 30, 66, 0.25);
    border-radius: 3px;
    text-align: left;
    margin: 2px 0 0;
    padding: 0;
    min-height: 0;
  }

  .drop-holder .dropdown .drop-header {
    display: none !important;
  }

  .drop-holder.active-drop .dropdown {
    left: 50%;
  }

  .drop-list {
    padding: 5px 0;
  }

  .drop-list + .drop-list {
    border-top: 1px solid #dfe1e5;
  }

  .drop-list + .drop-list:before {
    display: none;
  }

  .drop-list > li {
    border-bottom: none;
  }

  .drop-list > li a {
    color: #5f7aa3;
    padding: 5px 16px;
  }

  .drop-list > li a:hover {
    color: #37598c;
  }
}

@media (min-width: 1280px) {
  #header {
    font-size: 14px;
  }

  #header .container {
    /* padding: 7px 21px; */
  }

  .logo-holder {
    width: 211px;
    padding: 9px;
  }

  .right-block {
    /* width: 210px; */
  }

  .header-tools {
    padding: 0 9px 0 0;
  }

  .header-tools > li {
    margin: 0 0 0 12px;
  }

  .nav-drop {
    width: calc(100% - 421px);
  }

  .navigation > li {
    margin: 0 12px;
    padding: 16px 0;
  }

  .navigation > li > a .ico-holder {
    width: 24px;
    height: 24px;
    margin: -4px 5px -4px 0;
  }

  .navigation > li > a .ico-holder .icon {
    font-size: 18px;
  }

  .navigation > li > a .ico-holder .icon.icon-bullet-list {
    font-size: 10px;
  }

  .navigation > li > a .ico-holder .icon.icon-Path {
    font-size: 16px;
  }
}

@media (min-width: 1441px) {
  #header .container {
    padding: 0 35px;
  }
}

@media (max-width: 767px) {
  .right-block .header-tools {
    display: none !important;
  }

  .header-tools .tool-link:hover .text {
    color: #37598c;
  }

  .popup-holder .list:only-child > li:last-child {
    border-bottom: 1px solid #dfe1e5;
  }

  .popup-holder .list:last-child > li:last-child {
    border-bottom: 1px solid #dfe1e5;
  }

  .popup-holder .list > li a {
    color: #5f7aa3;
  }

  .popup-holder .list > li a:hover {
    color: #37598c;
  }
}
.MuiListItem-root {
  width: auto !important;
}
.MuiList-padding {
  padding: 0 0 !important;
  margin: 0 0 !important;
}
/* .MuiTooltip-tooltip{
    z-index: 99 !important;
} */
.display-none {
  display: none;
}
.drop-holder button {
  background: none;
  border: none;
  outline: none;
}
.drop-holder button:hover {
  background: none;
  border: none;
  outline: none;
}
.popup-holder button {
  background: none;
  border: none;
  outline: none;
}
.popup-holder button:hover {
  background: none;
  border: none;
  outline: none;
}
.popup-holder button {
  background: none;
  border: none;
  outline: none;
}
.header-open-close button {
  background: none;
  border: none;
  outline: none;
}
.header-open-close button:hover {
  background: none;
  border: none;
  outline: none;
}
.nav-opener-holder button:hover {
  background: white !important;
  border: none;
  outline: none;
}
.nav-opener-holder button {
  background: none;
  border: none;
  outline: none;
}
.view-mobile {
  display: none !important;
}
@media (max-width: 1023px) {
  .text {
    text-transform: capitalize;
  }
  .view-mobile {
    display: initial !important;
    padding-left: 5px;
  }
  .right-block .header-tools {
    display: none !important;
  }
  #header.nav-area-active .header-tools.mobile {
    display: block !important;
    padding: 8px 8px;
  }
  .popup-holder {
    width: 100%;
  }
  .popup-holder .popup-opener {
    width: inherit;
  }
  .header-tools .tool-link .text {
    font-size: revert;
    text-transform: none;
    font-weight: 100;
  }
}
