/*!
 * VRScheduler

 */

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}
root {
  height: 100%;
}
main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

/*Layout styles ******************************************************************************************************************************************************** */

body {
  font-family: "Roboto", sans-serif;
  background: #e5eaf1;
  font-size: 14px;
  color: #2a2f45;
}
.top-col {
  background: #fff;
}
.logo {
  margin: 0;
  display: block;
}
.top-col nav.navbar {
  padding: 4px 0 0;
}
.top-col ul.navbar-nav.mr-auto {
  margin-right: 0 !important;
  margin-left: auto;
}
.top-col .bg-light {
  background-color: #fff !important;
}
.secondary-menu {
  background: #dddddd;
}
.secondary-menu .bg-light {
  background-color: #dddddd !important;
}
.secondary-menu ul.navbar-nav.mr-auto {
  margin-right: auto;
  margin-left: auto;
}
.secondary-menu .navbar {
  padding: 0 15px;
}
.top-col ul.navbar-nav.mr-auto {
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 2px 5px 0 rgba(50, 50, 93, 0.06), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 0 10px;
  border-radius: 4px;
}
.top-col .navbar-light .navbar-nav .nav-link {
  font-size: 13px;
}
.secondary-menu .navbar-light .navbar-nav .nav-link {
  font-size: 13.8px;
  color: rgba(0, 0, 0, 0.5);
}
.secondary-menu .dropdown-item {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 26px;
}
.secondary-menu .navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 12px;
  padding-left: 13px;
}
.content-block {
  padding-top: 20px;
  padding-bottom: 20px;
}
.content {
  background: #fff;
  border-radius: 4px;
  padding: 15px 0;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}
h1,
h2 {
  font-family: "Roboto", sans-serif;
  color: #2a2f45;
  font-size: 22px;
  font-weight: 400;
}
.gray-block {
  background: #f6f7fb;
  padding: 15px 15px 10px;
  margin-bottom: 20px;
}
p {
  margin-bottom: 10px;
}
.secondary-menu .nav-link {
  padding: 0;
  line-height: 30px;
}
.secondary-menu .nav-link {
}
.logo img {
  /* max-width:30px;
    height:auto; */
  max-width: 143px;
}
footer {
  background: #333333;
  color: #fff;
  text-align: center;
  padding: 0 15px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  line-height: 25px;
  font-size: 13px;
}
footer p {
  margin: 0;
}
.nav-item a,
.dropdown-item:focus,
.dropdown-item:hover {
  color: rgba(0, 0, 0, 0.8);
  line-height: 30px;
  padding: 0 15px;
}
.nav-item button {
  color: rgba(0, 0, 0, 0.8);
  line-height: 30px;
  padding: 0 15px;
}
.secondary-menu .dropdown-item:focus,
.secondary-menu .dropdown-item:hover {
  line-height: 26px;
  background: none;
}
.dropdown-item {
  font-size: 13px;
  padding: 0 15px;
}
.content {
  margin-bottom: 50px;
  position: relative;
}
.g-btn {
  background: #66cc66;
  border: none;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 13.8px;
  line-height: 32px;
  padding: 0 15px;
  border-radius: 4px;
}
.g-btn:hover {
  background: #66cc66;
  -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 6px 8px rgba(0, 0, 0, 0.08);
}
.g-btn:focus,
.g-btn:active {
  background: #66cc66 !important;
  border: none !important;
  outline: none !important;
  -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 6px 8px rgba(0, 0, 0, 0.08);
}
h1 {
  margin-top: 0;
}
.check-col label {
  text-align: left;
  cursor: pointer;
  padding-top: 2px;
}
/*.modal-dialog h4 {*/
/*    font-size:16px;*/
/*    text-align:center;*/
/*    margin:0 auto;*/
/*}*/
/*.modal-header .close {*/
/*    padding:0;*/
/*    margin:0;*/
/*}*/
.modal .modal-body p {
  text-align: center;
  color: #212121;
  margin-bottom: 20px;
}
.modal .modal-body {
  background: #f6fafb;
}
.modal .modal-body label {
  text-align: center;
  display: block;
  margin: 0;
}
.modal .modal-body .check-col label {
  text-align: left;
  cursor: pointer;
}
/*.modal .modal-body  input.form-control {*/
/*    height:26px;*/
/*    border-radius:4px;*/
/*    border:solid 1px #e1e1e4;*/
/*}*/
/*** cheeck box style dead code**/
/*[type="checkbox"] label{
    text-align: left;
}*/
/*!* Base for label styling *!
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
}

!* checkbox aspect *!
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 19px;
    height:19px;
    border: 1px solid #dedede;
    background: #fff;
    border-radius: 4px;

}
!* checked mark aspect *!
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
    content: ' ';
    position: absolute;
    top:1px; left:7px;

    line-height: 0.8;
    color: #fff;
    transition: all .2s;
    width:6px;
    height:14px;
    border:solid 2px #fff;
    border-width:0 2px 2px 0;
    transform:rotate(45deg);
    -moz-transform:rotate(45deg);


}
!* checked mark aspect changes *!
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

!* disabled checkbox *!
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
    color: #999;
}
[type="checkbox"]:disabled + label {
    color: #aaa;
}
[type="checkbox"]:checked + label::before {
    background:#17598f;
    border-radius:4px;
    border: 1px solid #17598f;
}*/
.btn-plain {
  background: #fff;
  height: 26px;
  color: #37598c;
  padding: 1px 15px;
  font-size: 14px;

  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 4px 8px 0 rgba(50, 50, 93, 0.06), 0 2px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.btn-text-append {
  background: #fff;
  height: 26px;
  color: #37598c;
  padding: 0 4px;
  font-size: 14px;
  line-height: 26px;
  vertical-align: middle;
  border: solid 1px rgba(50, 50, 93, 0.08);
}
.btn-plain:hover {
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 4px 8px 0 rgba(50, 50, 93, 0.06), 0 6px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.btn-bl {
  background: #37598c;
  height: 26px;
  color: #fff;
  padding: 1px 20px;
  font-size: 14px;
  border: solid 1px #37598c;
}
.btn-bl:hover {
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.08),
    0 4px 8px 0 rgba(50, 50, 93, 0.06), 0 5px 3px 0 rgba(0, 0, 0, 0.05),
    0 2px 4px 0 rgba(0, 0, 0, 0.06);
  color: #fff;
}
a:focus,
a:hover {
  color: #014c8c;
  text-decoration: underline;
}
table {
  border-collapse: collapse;
  width: 100%;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a:hover {
  text-decoration: none;
}
.select-field select {
  -moz-appearance: none;
  -webkit-appearance: none;
  line-height: 29px;
  padding: 0 25px 0 10px;
  border: solid 1px #f1f3f3;
  border-radius: 4px;
  color: #17598f;
  font-size: 14px;
}
.bill-data tr td.amt-col {
  font-weight: 500;
}
.br-none {
  border-right: none;
}
.input-icon {
  background-color: white;
  cursor: pointer;
}
.password-text {
  text-align: center;
  color: #212121;
  margin-right: 68px;
  margin-top: -14px;
  font-size: 12px;
}
.page-title {
  padding: 0 15px;
}
.p-y-22 {
  padding: 0 24px !important;
}
.text-center {
  text-align: center;
}
.content-unset {
  justify-content: space-between;
}
@media (max-width: 991px) {
  .navbar-light .navbar-toggler {
    border: none;
  }

  .dropdown-item {
    font-size: 13px;
    padding: 0 15px;
  }
  .top-col .navbar-toggler {
    margin-left: auto;
    float: right;
  }
  #navbarSupportedContent {
    max-width: 220px;
    position: absolute;
    right: 0px;
    top: 34px;
    z-index: 9;
    width: 100%;
  }
  .secondary-menu .navbar {
    padding: 0;
  }
  .secondary-menu .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .logo {
    /* margin-left:30px; */
  }
  .secondary-menu .navbar-toggler {
    position: absolute;
    top: -29px;
  }
  .secondary-menu .navbar-toggler:focus {
    outline: none;
  }
  .secondary-menu .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 15px;
    border-bottom: solid 1px #e8e6e6;
  }
  .secondary-menu {
    padding: 0;
  }
  .bar1,
  .bar2,
  .bar3 {
    width: 20px;
    height: 2px;
    background-color: #5b77a1;
    margin: 3px 0;
    transition: 0.4s;
  }
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }

  button.navbar-toggler[aria-expanded="true"] .bar2 {
    opacity: 0;
  }

  button.navbar-toggler[aria-expanded="true"] .bar1 {
    -webkit-transform: rotate(-45deg) translate(-3px, 4px);
    transform: rotate(-45deg) translate(-3px, 4px);
  }
  button.navbar-toggler[aria-expanded="true"] .bar3 {
    -webkit-transform: rotate(45deg) translate(-3px, -4px);
    transform: rotate(45deg) translate(-3px, -4px);
  }
  .bill-data {
    width: 100%;
    overflow-x: scroll;
  }
  a.ellipis-btn {
    width: 37px;
  }
}
.inlineBlock {
  display:inline-block;
}

@media (max-width: 767px) {
  h1.page-title {
    padding-right: 100px;
  }
}

@media (max-width: 414px) {
  .content {
    margin-bottom: 50px;
  }

  h1,
  h2 {
    font-size: 18px;
  }
}


